<template>
    <v-btn size="small" @click="load()">
      {{ $store.getters.translate("messages") }}
    </v-btn>
    <BaseDraggableModal
        name="base_notification"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        ref="base_notification">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ $store.getters.translate("messages") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="closeModal('base_notification');">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ma-5">
              <v-data-table
                  :headers="headers"
                  :items="notifications"
                  :loading="loading"
                  :show-select="false"
                  v-bind="footer_props">
                <template v-slot:item.data="{ item }">
                  <div class="mt-2 mb-2">
                    <p v-html="item.data.message"/>
                    <v-chip size="x-small" label>
                      <v-icon start size="x-small" icon="mdi-eye"/>
                      <span>{{ item.read_at ? moment(item.read_at).format("DD-MM-YYYY") : $store.getters.translate("has_not_been_read") }}</span>
                    </v-chip>
                    <v-chip class="ml-2" size="x-small" label>
                      <v-icon start size="x-small" icon="mdi-plus"/>
                      <span>{{ item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : "-" }}</span>
                    </v-chip>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon @click="deleteNotification(item.id)" end color="red" icon="mdi-trash-can"/>
                </template>
              </v-data-table>
              <base-message :label="$store.getters.translate('send_notification')" :modelValue="record.message" @change="setMessage"/>
            </v-card-text>
          </template>
          <template #footer>
            <v-card-actions class="ml-5 mr-5 mb-5">
              <v-btn @click="save()" :disabled="!record.message || loading" variant="text">
                {{ $store.getters.translate("save_template") }}
              </v-btn>
              <v-spacer/>
              <v-btn @click="sendNotification()" :disabled="!record.message || loading" color="primary" variant="text">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-card-actions>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["user_id"],
  data() {
    return {
      loading: false,
      record: {},
      notifications: [],
      headers: [
        {
          title: this.$store.getters.translate("message"),
          align: "start",
          sortable: true,
          value: "data",
          width: "90%",
        },
        {
          title: this.$store.getters.translate("actions"),
          sortable: false,
          align: "right",
          value: "actions",
          width: "10%",
        },
      ],
      footer_props: helpFunctions.footer_props,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {};
      this.openModal('base_notification');
      this.getNotifications();
    },
    sendNotification() {
      this.loading = true;
      this.record.user_id = this.user_id;
      this.$http
        .post(this.$store.getters.appUrl + "v2/notifications", this.record)
        .then(() => {
          this.loading = false;
          this.$toast.success(this.$store.getters.translate("success"));
          this.notifications.unshift({
            data: { message: this.record.message },
            read_at: null,
          });
          this.record = {};
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.response?.data?.error ?? error.message);
          }
          this.loading = false;
        });
    },
    save() {
      if(!this.record.message) {
        this.$toast.error(this.$store.getters.translate("please_enter_message"));
      }
      else {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/messagetemplates", this.record)
            .then(() => {
              this.loading = false;
              this.$toast.success(this.$store.getters.translate("successfully_saved"));
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    getNotifications() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/get-notifications/" + this.user_id)
          .then((response) => {
            this.loading = false;
            this.notifications = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    deleteNotification(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/notifications/" + id)
        .then(() => {
          this.loading = false;
          this.$toast.success(this.$store.getters.translate("successfully_deleted"));
          this.getNotifications();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.message);
          }
          this.loading = false;
        });
    },
    setMessage(data) {
      this.record.message = data;
    },
  },
};
</script>