<template>
    <BaseDraggableModal
        name="download_contract"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        ref="download_contract">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-btn size="small" @click="closeModal('download_contract')">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
              <v-toolbar-title>{{ $store.getters.translate('template') }}</v-toolbar-title>
              <v-spacer/>
              <v-btn :disabled="loading" size="small" @click="$emit('downloadContract', template_id)">
                <v-icon icon="mdi-download" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
              <v-card-text class="ma-5">
                <v-autocomplete
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :items="templates"
                    v-model="template_id"
                    :label="$store.getters.translate('template')"
                    item-title="name"
                    item-value="id"/>
              </v-card-text>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      loading: false,
      templates: [],
      template_id: null
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.templates = [];
      this.openModal('download_contract');
      this.loadTemplates();
    },
    async loadTemplates() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "Template" })
          .then((response) => {
            this.loading = false;
            this.templates = response.data;
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
  }
};
</script>