<template>
  <v-toolbar flat color="white" dense height="unset" class="mt-5">
    {{ label }}
    <v-spacer/>
    <v-btn v-if="!preview" @click.stop="$emit('createLink')" size="small" color="primary">
      {{ $store.getters.translate('create') }}
      <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
    </v-btn>
  </v-toolbar>
    <v-list v-if="links && links.length > 0" density="compact">
      <v-list-item v-for="(link, index) in links" :key="index" color="primary">
        <v-list-item-title>
          <a :href="link.url" target="_blank">{{ link.name }}</a>
        </v-list-item-title>
        <template v-if="!preview" v-slot:append>
          <v-list-item-action>
            <v-icon @click.stop="deleteLink(link)" color="red" icon="mdi-trash-can"/>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
  <div class="mt-5" v-else>
    {{ $store.getters.translate("nothing_found") }}
  </div>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["model_type", "model_id", "label", "preview"],
  data() {
    return {
      page: helpFunctions.pages.externallinks,
      links: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if(this.model_id) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?model_type=" + this.model_type + "&model_id=" + this.model_id)
            .then((response) => {
              this.links = response.data;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    deleteLink(link) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, link.id)) {
            await this.load();
          }
          this.loading = false;
        }
      });
    },
  },
  watch: {
    model_id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>